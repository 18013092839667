<template>
  <section class="classification-wrap" >
    <section class="classification-list" v-if="!editSow">
      <el-button type="primary" size="mini" class="add-btn" @click="addRule">创建</el-button>
      <el-table
        :data="tableData"
        border
        @sort-change="sortChange"
        style="width: 100%">
        <el-table-column  :resizable="false"
          prop="name"
          label="规则名称"
        >
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="content"
          label="规则内容"
        >
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="date"
          sortable="'custom'"
          label="创建时间">
        </el-table-column>
        <el-table-column  :resizable="false"
          label="操作">
          <template scope="{ row }">
            <el-button type="text" @click="edit(row.id)">修改</el-button>
            <el-button type="text" @click="remove(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </section>
    <section class="edit-wrap" v-else>
      <h3 class="title">修改分类规则</h3>
      <el-form :model="editform" status-icon ref="ruleForm" label-width="80px">
        <el-form-item label="规则名称">
          <el-input v-model="editform.ruleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="包含词">
          <textarea v-model="editform.words" class="word-text"></textarea>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" size="mini">保存</el-button>
          <el-button @click="togo" size="mini">返回主题</el-button>
        </el-form-item>
      </el-form>
    </section>
    <el-dialog :title="dialog.title" :visible.sync="dialog.visible" class="dialog" width="30%" top="30vh">
      <el-form :model="form">
        <el-form-item label="规则名称" :label-width="'80'" v-if="dialog.type === 'add'">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template v-if="dialog.type === 'remove'">
        <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="define" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  data () {
    return ({
      tableData: [{
        id: 1,
        name: '万科地产高管',
        content: '陈建忠+丁宁',
        date: '2019-11-06 15:32'
      }, {
        id: 2,
        name: '宁波万科',
        content: '宁波传奇里',
        date: '2019-11-06 15:42'
      }],
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      form: {
        name: ''
      },
      editform: {
        ruleName: '',
        words: ''
      },
      dialog: {
        visible: false,
        title: '',
        type: ''
      },
      editSow: false,
      editId: null
    })
  },
  methods: {
    sortChange () {
      console.log(arguments)
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    addRule () {
      this.dialog.visible = true
      this.dialog.title = '添加规则'
      this.dialog.type = 'add'
    },
    edit (id) {
      this.editSow = true
      this.editId = id
    },
    remove (id) {
      console.log(id)
      this.dialog.visible = true
      this.dialog.title = '提示'
      this.dialog.type = 'remove'
    },
    define () {
      if (this.dialog.type === 'add') {
        // 添加
      }
      if (this.dialog.type === 'remove') {
        // 删除
      }
      this.dialog.visible = false
    },
    save () {},
    togo () {
      this.$router.push('/settings/theme')
    }
  }
}
</script>

<style lang="scss">
.classification-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  .add-btn {
    margin-bottom: 20px;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
  .dialog {
    .el-dialog__body {
      padding: 10px 20px;
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
  .edit-wrap {
    width: 600px;
    .word-text {
      width: 100%;
      height: 100px;
      background-color: #FFFFFF;
      color: #606266;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
    }
  }
}
</style>
